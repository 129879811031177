import React from 'react'
import { graphql } from 'gatsby'
import _get from 'lodash/get'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import Post from '../components/Post'

class BlogPage extends React.Component {
  render() {
    const siteTitle = _get(this, 'props.data.site.siteMetadata.title')
    const posts = _get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="My personal blog" />
        <aside>
          <Bio />
        </aside>
        <main>
          {posts.map(({ node }) => (
            <Post post={node} key={node.fields.slug} />
          ))}
        </main>
        <Footer />
      </Layout>
    )
  }
}

export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`

// import React from 'react'
// import _get from 'lodash/get'
// import { graphql } from 'gatsby'
// import Image from 'gatsby-image'
// import styled from 'styled-components'
// import { rhythm } from '../utils/typography'

// import Layout from '../components/Layout'
// import SEO from '../components/SEO'

// const Wrapper = styled.div`
//   position: relative;
//   p {
//     text-align: center;
//   }

//   p:nth-child(3) {
//     margin-top: ${rhythm(20)};
//   }

//   .avatar {
//     text-align: center;
//     margin: ${`${rhythm(2)} 0`};
//   }
// `

// function HomePage({ location, data }) {
//   const siteTitle = _get(data, 'site.siteMetadata.title')
//   const author = _get(data, 'site.siteMetadata.author')
//   const avatar = _get(data, 'avatar.childImageSharp.fixed')

//   return (
//     <Layout fullWidth location={location} title={siteTitle}>
//       <SEO title="Thuan Nguyen" />
//       <Wrapper>
//         <div className="avatar">
//           <Image
//             fixed={avatar}
//             alt={author}
//             style={{
//               marginRight: rhythm(1 / 2),
//               marginBottom: 0,
//               minWidth: 130,
//               borderRadius: `100%`,
//             }}
//             imgStyle={{
//               borderRadius: `50%`,
//             }}
//           />
//         </div>
//         <main>
//           <p>Hi folks, I'm Thuan</p>
//           <p>Welcome to my Home</p>
//           <p>© 2020 thuannp</p>
//         </main>
//       </Wrapper>
//     </Layout>
//   )
// }

// export default HomePage

// export const pageQuery = graphql`
//   query PageQuery {
//     avatar: file(absolutePath: { regex: "/profile-picture-art.jpg/" }) {
//       childImageSharp {
//         fixed(width: 130, height: 130) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     site {
//       siteMetadata {
//         title
//         author
//         social {
//           twitter
//         }
//       }
//     }
//   }
// `
